import { useRouter } from "next/router";
import React from "react";
import { useEffect } from "react";
import { CircularProgressIndicator } from "../../../common/CircularProgressIndicator/CircularProgressIndicator";

const ClubRedirectPage = ({ pathname, query }) => {
  const router = useRouter();

  useEffect(() => {
    router.push({ pathname, query }, pathname);
  }, [pathname, query]);

  return <CircularProgressIndicator />;
};

export { ClubRedirectPage };
