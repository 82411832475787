import React, { useMemo } from "react";

import { NavbarFooterContext } from "./NavbarFooterContext";

import { ClubPage } from "../routePages/";

import { scheduleViews } from "../constants/constants";

import { GET_CLUB } from "../../../../graphql/queries/queriesClub";
import { useTranslation } from "next-i18next";

import { useQuery } from "@apollo/client";

function ClubPageWrapper({ subDomain = null }) {
  // const router = useRouter();
  // const {
  //   query: { club: activeParamsClub },
  //   pathname,
  // } = router;

  // const activeClub = subDomain || activeParamsClub;

  // Проверяем перешли на страницу по ссылке или из нашей страницы
  // const [isHistoryPush] = useState(router.action !== "POP");

  const {
    i18n: { language },
  } = useTranslation();

  const { data: { club: clubData = {} } = {} } = useQuery(GET_CLUB, {
    variables: { language },
  });

  const stateProps = useMemo(() => {
    const { scheduleDefault } = clubData;
    return {
      scheduleDefaultView: scheduleViews[scheduleDefault],
      // veteranPeriod,
    };
  }, [clubData]);

  return (
    <>
      <NavbarFooterContext.Provider value={stateProps}>
        <ClubPage {...clubData} />
      </NavbarFooterContext.Provider>
      <style jsx global>{`
        @import "./ClubPageWrapper.scss?";
      `}</style>
    </>
  );
}

export default ClubPageWrapper;
