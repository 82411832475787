import React from "react";
import { GET_CLUB } from "../../../graphql/queries/queriesClub";
import { withConfig } from "../../../utils/withConfig";
import { initializeApollo, addApolloState } from "../../../utils/appollo";
import ClubPageWrapper from "../../../components/pagesComponents/Clubs/containers/ClubPageWrapper";
import {
  CARD_TEMPLATES,
  CARD_TEMPLATES_GROUP,
} from "../../../graphql/queries/queriesCard";

import { getSeoFields } from "../../../utils/getSeoFields";
import { GET_CLUB_ANNOUNCES } from "../../../graphql/queries/queriesAnnounce";
import { GET_BLOGS } from "../../../graphql/queries/queriesNews";
import { getAlternateClubLink } from "../../../utils/getAlternateLink";
import { ClubRedirectPage } from "../../../components/pagesComponents/Clubs/containers/ClubRedirectPage";
import { getClubSlugPathsBlocking } from "../../../utils/getClubSlugPaths";
import { COUNTRY_ROUTE } from "../../../components/pagesComponents/Cities/constants/ownConstants";
import { getStaticPropsDecorator } from "../../../utils/getPropsDecorator";

export const getStaticPaths = getClubSlugPathsBlocking;

const callback = async ({ locale, params: { club } }) => {
  const apolloClient = initializeApollo({ key: club, locale });

  const { data: { club: clubData = {} } = {}, errors } = await apolloClient
    .query({
      query: GET_CLUB,
      errorPolicy: "all",
    })
    .catch((e) => {
      if (e.networkError?.result?.errors)
        return { errors: e.networkError.result.errors };
      return { errors: e };
    });

  if (errors) {
    return {
      props: {
        locale,
        redirect: COUNTRY_ROUTE,
        ...(await withConfig(locale)),
      },
    };
  }

  // if (errors) {
  //   return {
  //     redirect: {
  //       destination: COUNTRY_ROUTE,
  //       permanent: false,
  //     },
  //     props: {},
  //   };
  // }

  const queries = [
    // { query: GET_CLUB },
    { query: CARD_TEMPLATES },
    { query: CARD_TEMPLATES_GROUP },
    { query: GET_CLUB_ANNOUNCES },
    { query: GET_BLOGS },
  ];

  const promises = queries.map(({ query, variables }) =>
    apolloClient.query({ query, variables })
  );

  // const [{ data: { club: clubData = {} } } = {}] =
  await Promise.all(promises);

  const pageProps = {
    props: {
      club,
      locale,
      tags: getSeoFields({
        ...clubData,
        alternate: `${getAlternateClubLink({ locale, club })}`,
      }),
      ...(await withConfig(locale)),
    },
  };

  return addApolloState(apolloClient, pageProps);
};

export const getStaticProps = getStaticPropsDecorator(callback);

const ClubMainPage = ({ redirect = "", query }) => {
  if (!redirect) return <ClubPageWrapper />;

  return <ClubRedirectPage pathname={redirect} query={query} />;
};

ClubMainPage.navbar = null;

export default ClubMainPage;
